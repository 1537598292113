<template>
  <v-container fluid>
    <v-card class="elevation-11">
      <v-card-title class="accent">
        <h3>Credit Notes Register</h3>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" md="2">
              <v-switch
                v-model="allCustomers"
                label="All Customer"
                dense
                @click="clearData()"
              ></v-switch>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-autocomplete
                prepend-inner-icon="mdi-account"
                v-model="customer"
                :items="customers"
                item-text="clastname"
                item-value="custledgkey"
                label="Customer"
                persistent-hint
                return-object
                single-line
                dense
                outlined
                :disabled="allCustomers"
              ></v-autocomplete>
            </v-col>
            <v-col cols="12" sm="6" md="2">
              <v-menu
                v-model="startDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatStartDate"
                    label="Start Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="startDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="startDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" sm="6" md="2">
              <v-menu
                v-model="endDateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="formatEndDate"
                    label="End Date"
                    readonly
                    outlined
                    dense
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="endDate"
                  :min="this.calcMinDate()"
                  :max="this.calcMaxDate()"
                  @input="endDateMenu = false"
                ></v-date-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="2">
              <v-btn
                color="btn-large btn-block primary white--text"
                class="mr-2"
                @click="getCrNotesReg()"
                :disabled="!valid"
                >Generate</v-btn
              >
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <v-card class="mt-5" elevation-11>
      <v-card-title>
        <v-col cols="12" md="10">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            outlined
            dense
            hide-details
          ></v-text-field>
        </v-col>
        <v-col cols="12" md="2">
          <v-btn
            color="btn-large btn-block primary white--text"
            class="mr-2"
            @click="downloadXLS"
            :disabled="!valid"
            >XLS</v-btn
          >
          <vue-json-to-csv
            :json-data="crnotesregn"
            :csv-title="`AEJ-CNR-${this.startDate}-${this.endDate}`"
          >
            <v-btn color="btn-large btn-block primary white--text"> CSV </v-btn>
          </vue-json-to-csv>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-data-table
          :loading="loading"
          :search="search"
          :headers="headers"
          :items="crnotesregn"
          item-key="invkey"
          class="elevation-1"
          loading-text="Retrieving data... please wait"
        >
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
import AdminLayout from "@/layouts/AdminLayout";
import api from "../../services/axiosconfig";
import moment from "moment";
import VueJsonToCsv from "vue-json-to-csv";

export default {
  components: {
    VueJsonToCsv,
  },
  data() {
    return {
      valid: true,
      search: "",
      loading: false,
      allCustomers: true,
      headers: [
        {
          text: "Credit No.",
          align: "start",
          value: "creditnum",
          width: "100px",
        },
        {
          text: "Credit Date",
          align: "start",
          value: "creditdate",
          width: "150px",
        },
        {
          text: "Inv No.",
          align: "start",
          value: "invoicenum",
          width: "100px",
        },
        {
          text: "Inv Date",
          align: "start",
          value: "invoicedate",
          width: "150px",
        },
        {
          text: "Booking No.",
          align: "start",
          value: "bookingnum",
          width: "100px",
        },
        {
          text: "Booking Date",
          align: "start",
          value: "bookingdate",
          width: "150px",
        },
        {
          text: "Agency Ref",
          align: "start",
          value: "agencyref",
          width: "150px",
        },
        {
          text: "Account Ref",
          align: "start",
          value: "accountref",
          width: "150px",
        },
        { text: "PAX Type", align: "start", value: "paxtype", width: "100px" },
        { text: "PAX Name", align: "start", value: "paxname", width: "200px" },
        { text: "Locator", align: "start", value: "pnrno", width: "150px" },
        {
          text: "Final Destination",
          align: "start",
          value: "finaldest",
          width: "200px",
        },
        {
          text: "Ticket Number",
          align: "start",
          value: "ticketno",
          width: "200px",
        },
        {
          text: "Ticket Issue Date",
          align: "start",
          value: "ticketdate",
          width: "150px",
        },
        {
          text: "Departure Date",
          align: "start",
          value: "deptdate",
          width: "150px",
        },
        {
          text: "Airline Code",
          align: "start",
          value: "airline",
          width: "100px",
        },
        { text: "Flight Number", align: "start", value: "flightno" },

        { text: "Class", align: "start", value: "class", width: "100px" },
        {
          text: "Class Type",
          align: "start",
          value: "cabintype",
          width: "150px",
        },
        { text: "Currency", align: "start", value: "currency", width: "100px" },
        {
          text: "Invoice Amt",
          align: "end",
          value: "invoiceamt",
          width: "200px",
        },
        {
          text: "Credit Amt",
          align: "end",
          value: "creditvalue",
          width: "200px",
        },
      ],

      crnotesregn: [],
      customers: [],
      carriers: [],
      airports: [],
      cabins: [],
      customer: {},

      startDateMenu: false,
      endDateMenu: false,

      startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),

      endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substring(0, 10),
    };
  },

  mounted() {
    this.getCarriers();
    this.getAirports();
    this.getCabins();
    this.getCustomers();
  },

  created() {
    this.$emit(`update:layout`, AdminLayout);
  },

  computed: {
    formatStartDate() {
      return `${moment(this.startDate).format("DD-MM-YYYY")}`;
    },

    formatEndDate() {
      return `${moment(this.endDate).format("DD-MM-YYYY")}`;
    },
  },

  methods: {
    clearData() {
      this.crnotesregn = [];
      this.customer = {};
    },

    calcMinDate() {
      return moment()
        .subtract(localStorage.getItem("PERMITTED"), "months")
        .startOf("month")
        .format("YYYY-MM-DD");
    },

    calcMaxDate() {
      return moment().endOf("month").format("YYYY-MM-DD");
    },

    validateData() {
      if (moment(this.startDate).isAfter(this.endDate)) {
        this.$toasted.global.optimus_error({
          message: "Start date must be on or before the end date.",
        });
        return false;
      } else if (
        !this.allCustomers &&
        Object.keys(this.customer).length === 0
      ) {
        this.$toasted.global.optimus_error({
          message: "Select a customer from the drop down list.",
        });
        return false;
      } else {
        return true;
      }
    },

    dateFormat(dateString) {
      return moment(new Date(dateString)).format("DD/MM/YYYY");
    },

    async getCrNotesReg() {
      if (this.validateData()) {
        this.crnotesregn = [];
        try {
          let response = "";
          this.loading = true;
          if (!this.allCustomers) {
            response = await api.post("/aejmis/api/creditnotes", {
              startdate: this.startDate,
              enddate: this.endDate,
              ledgerkey: this.customer.custledgkey,
            });
          } else {
            response = await api.post("/aejmis/api/allcreditnotes", {
              startdate: this.startDate,
              enddate: this.endDate,
            });
          }
          this.generateCrNotesReg(response.data);
          this.loading = false;
        } catch (error) {
          console.log(error);
          this.loading = false;
          this.$toasted.global.optimus_error({
            message: "Could not retrieve information. Try after sometime.",
          });
        }
      }
    },

    generateCrNotesReg(rawData) {
      this.crnotesregn = [];
      rawData.map(
        ({
          creditnote,
          invoicenum,
          invoicedate,
          bookingnum,
          bookingdate,
          depdate,
          locator,
          firstname,
          lastname,
          agencyref,
          accountref,
          finaldest,
          costing,
          routing,
        }) => {
          this.crnotesregn.push({
            creditnum: creditnote.creditnum,
            creditdate: this.dateFormat(creditnote.creditdate),
            invoicenum: invoicenum,
            invoicedate: moment(new Date(invoicedate)).format("DD/MM/YYYY"),
            bookingnum: bookingnum,
            bookingdate: moment(new Date(bookingdate)).format("DD/MM/YYYY"),
            agencyref: agencyref,
            accountref: accountref,
            paxtype: this.getPaxType(costing),
            paxname: `${firstname.trim()} ${lastname.trim()}`,
            pnrno: locator,
            finaldest: finaldest,
            ticketno: this.getTicketNum(routing, costing),
            ticketdate: this.getTicketDate(costing),
            deptdate: this.dateFormat(depdate),
            airline: this.getAirline(routing),
            flightno: this.getFlightNum(routing),
            class: this.getCabin(routing),
            cabintype: this.getCabinType(routing),
            currency: "GBP",
            creditvalue: parseFloat(creditnote.creditnotevalue).toFixed(2),
            invoiceamt: this.calcInvTotal(costing),
          });
        }
      );
    },

    getAirline(routes) {
      let first = true;
      let carrier = "";
      routes.forEach((route) => {
        if (route.airline.length > 0 && first) {
          carrier = route.airline;
          first = false;
        }
      });
      return carrier;
    },

    getTicketNum(routes, costing) {
      let first = true;
      let prefix = "";
      let account = "";
      routes.forEach((route) => {
        if (route.airline.length > 0 && first) {
          account = this.carriers.filter(
            (airline) => airline.iata === route.airline
          );
          first = false;
        }
      });

      if (account.length) {
        prefix = account[0].accounting;
      } else {
        prefix = "NIL";
      }

      let ticketno = "";
      let tktFirst = true;
      costing.forEach((costItem) => {
        if (tktFirst) {
          costItem.ticket.forEach((ticketItem) => {
            ticketno = ticketItem.ticketnum;
          });
          tktFirst = false;
        }
      });
      return `${prefix}-${ticketno}`;
    },

    getTicketDate(costing) {
      let ticketdt = "";
      let tdtFirst = true;
      costing.forEach((costItem) => {
        if (tdtFirst) {
          costItem.ticket.forEach((ticketItem) => {
            ticketdt = ticketItem.issuedate;
          });
          tdtFirst = false;
        }
      });
      return this.dateFormat(ticketdt);
    },

    getPaxType(costing) {
      let paxtype = "";
      let paxFirst = true;
      costing.forEach((costItem) => {
        if (paxFirst) {
          costItem.ticket.forEach((ticketItem) => {
            paxtype = ticketItem.adchinfcon;
          });
          paxFirst = false;
        }
      });
      return paxtype;
    },

    getFlightNum(routes) {
      let first = true;
      let flightno = "";
      routes.forEach((route) => {
        if (route.flight.length > 0 && first) {
          flightno = route.flight;
          first = false;
        }
      });
      return flightno;
    },

    getCabin(routes) {
      let first = true;
      let cabin = "";
      routes.forEach((route) => {
        if (route.class.length > 0 && first) {
          cabin = route.class;
          first = false;
        }
      });
      return cabin;
    },

    getCabinType(routes) {
      let firstCarrier = true;
      let carrier = "";
      routes.forEach((route) => {
        if (route.airline.length > 0 && firstCarrier) {
          carrier = route.airline;
          firstCarrier = false;
        }
      });

      const cabins = routes.filter((route) => route.airline === carrier);
      let firstCabin = true;
      let cabinClass = "";
      cabins.forEach((cabin) => {
        if (cabin.class.length > 0 && firstCabin) {
          cabinClass = cabin.class;
          firstCabin = false;
        }
      });

      const cabinDecoded = this.cabins.filter(
        (cabin) => cabin.cabincode === cabinClass.toUpperCase()
      );
      if (cabinDecoded.length > 0) {
        return cabinDecoded[0].cabinname;
      } else {
        return "";
      }
    },

    calcInvTotal(costings) {
      let amount = 0;
      costings.forEach((item) => {
        amount = amount + item.quantity * item.gross;
      });
      return parseFloat(amount).toFixed(2);
    },

    async getCustomers() {
      try {
        this.loading = true;
        const response = await api.get("/aejmis/api/customers");
        this.customers = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
      }
    },

    async getCabins() {
      try {
        this.cabins = [];
        this.loading = true;
        const response = await api.get("/aejmis/api/cabins");
        this.cabins = response.data;
        console.log(this.cabins);
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toasted.global.optimus_error({
          message: "Could not retrieve cabin information. Try after sometime.",
        });
      }
    },

    async getCarriers() {
      try {
        this.airlines = [];
        this.loading = true;
        const response = await api.get("/aejmis/api/airlines");
        this.carriers = response.data;
        this.loading = false;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toasted.global.optimus_error({
          message: "Could not airlines information. Try after sometime.",
        });
      }
    },

    async getAirports() {
      try {
        this.loading = true;
        const response = await api.get("/aejmis/api/airports");
        const data = response.data;
        data.map((airport) => {
          if (airport.cityname != null) {
            this.airports.push({
              citycode: airport.citycode.trim(),
              cityname: airport.cityname.trim(),
            });
          }
        });
        console.log(this.airports);
        this.loading = false;
        return;
      } catch (error) {
        console.log(error);
        this.loading = false;
        this.$toasted.global.optimus_error({
          message: "Could not retrieve information. Try after sometime.",
        });
      }
    },

    async downloadXLS() {
      const ExcelJS = require("exceljs");
      const fileSaver = require("file-saver");

      // Create workbook & add worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Airline Ranking");

      // add column headers
      worksheet.columns = [
        { header: "Credit No.", key: "creditnum", width: 10 },
        { header: "Credit Date", key: "creditdate", width: 15 },
        { header: "Invoice No.", key: "invoicenum", width: 10 },
        { header: "Invoice Date", key: "invoicedate", width: 15 },
        { header: "Booking No.", key: "bookingnum", width: 10 },
        { header: "Booking Date", key: "bookingdate", width: 15 },
        { header: "Agency Ref", key: "agencyref", width: 20 },
        { header: "Account Ref", key: "accountref", width: 20 },
        { header: "Pax Type", key: "paxtype", width: 10 },
        { header: "Pax Name", key: "paxname", width: 50 },
        { header: "Locator", key: "pnrno", width: 15 },
        { header: "Final Destination", key: "finaldest", width: 20 },
        { header: "Ticket No.", key: "ticketno", width: 20 },
        { header: "Ticket Issue Date", key: "ticketdate", width: 15 },
        { header: "Departure Date", key: "deptdate", width: 15 },
        { header: "Airline", key: "airline", width: 10 },
        { header: "Flight No.", key: "flightno", width: 15 },
        { header: "Class", key: "class", width: 10 },
        { header: "Class Type", key: "cabintype", width: 20 },
        { header: "Currency", key: "currency", width: 10 },
        { header: "Invoice Amt", key: "invoiceamt", width: 15 },
        { header: "Credit Amt", key: "creditvalue", width: 15 },
      ];

      // make header bold
      worksheet.getRow(1).font = { name: "Roboto", bold: true };

      // style columns
      worksheet.columns[0].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[1].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[2].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[3].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[4].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[5].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[6].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[7].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[8].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[9].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[10].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[11].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[12].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[13].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[14].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[15].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[16].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[17].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[18].alignment = {
        vertical: "middle",
        horizontal: "left",
      };
      worksheet.columns[19].alignment = {
        vertical: "middle",
        horizontal: "center",
      };
      worksheet.columns[20].alignment = {
        vertical: "middle",
        horizontal: "right",
      };
      worksheet.columns[21].alignment = {
        vertical: "middle",
        horizontal: "right",
      };

      // Add rows using both the above of rows
      if (this.crnotesregn.length > 0) {
        let rows = [];
        this.crnotesregn.forEach((crnote) => {
          rows.push({
            creditnum: crnote.creditnum,
            creditdate: crnote.creditdate,
            invoicenum: crnote.invoicenum,
            invoicedate: crnote.invoicedate,
            bookingnum: crnote.bookingnum,
            bookingdate: crnote.bookingdate,
            agencyref: crnote.agencyref,
            accountref: crnote.accountref,
            paxtype: crnote.paxtype,
            paxname: crnote.paxname,
            airline: crnote.airline,
            pnrno: crnote.pnrno,
            finaldest: crnote.finaldest,
            ticketno: crnote.ticketno,
            ticketdate: crnote.ticketdate,
            deptdate: crnote.deptdate,
            flightno: crnote.flightno,
            class: crnote.class,
            cabintype: crnote.cabintype,
            currency: crnote.currency,
            creditvalue: crnote.creditvalue,
            invoiceamt: crnote.invoiceamt,
          });
        });

        worksheet.addRows(rows);

        workbook.xlsx.writeBuffer().then((data) => {
          const blob = new Blob([data], { type: "application/octet-stream" });
          fileSaver.saveAs(
            blob,
            `AEJ-CNR-${this.startDate}-${this.endDate}.xlsx`
          );
        });
      }
    },
  },
};
</script>
